import React from "react";
import "../stylesheets/Pricing.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import { Link } from "react-scroll";

const Pricing = () => {
  return (
    <section className="pricing" id="pricing">
      <div className="headline">Syntax Lane: Simple, Transparent Pricing</div>
      <Typography variant="h3">
        Choose the plan that best matches your business needs & goals
      </Typography>
      <div className="pricing-chart">
        <Card sx={{ minWidth: 200, maxWidth: 400, textAlign: "center" }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Front-End
            </Typography>
            <Typography variant="h5" component="div">
              Starter
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Perfect for basic websites
            </Typography>
            <ul>
              <li>Custom Homepage</li>
              <li>4 Custom Inner Pages</li>
              <li>Custom Banner Design</li>
              <li>Cross-Browser Compatibility</li>
              <li>Full Deployment</li>
              <li>5 Days Turnaround Time</li>
            </ul>
          </CardContent>
          <CardActions>
            <Typography variant="body1" component="div">
              Contact for pricing
            </Typography>
            <Link to="contact">
              <div className="btn">Get Started</div>
            </Link>{" "}
          </CardActions>
        </Card>

        <Card sx={{ minWidth: 200, maxWidth: 400, textAlign: "center" }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Full-Stack
            </Typography>
            <Typography variant="h5" component="div">
              Business
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Perfect for business / commerce
            </Typography>
            <ul>
              <li>Custom Homepage</li>
              <li>8 Custom Inner Pages</li>
              <li>Custom Banner Design</li>
              <li>Database Integration</li>
              <li>Cross-Browser Compatibility</li>
              <li>Full Deployment</li>
              <li>1 Week Turnaround Time</li>
            </ul>
          </CardContent>
          <CardActions>
            <Typography variant="body1" component="div">
              Contact for pricing{" "}
            </Typography>
            <Link to="contact">
              <div className="btn">Get Started</div>
            </Link>
          </CardActions>
        </Card>

        <Card sx={{ minWidth: 200, maxWidth: 400, textAlign: "center" }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {" "}
              Custom Software
            </Typography>
            <Typography variant="h5" component="div">
              Enterprise
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Perfect for medium-large businesses
            </Typography>
            <ul>
              <li>Custom Software</li>
              <li>Performant & Responsive</li>
              <li>Cross-Browser Compatibility</li>
              <li>Full Deployment</li>
            </ul>
          </CardContent>
          <CardActions>
            <Typography variant="body1" component="div">
              Contact for pricing
            </Typography>
            <Link to="contact">
              <div className="btn">Get Started</div>
            </Link>
          </CardActions>
        </Card>
      </div>
    </section>
  );
};

export default Pricing;
