import React, { useRef, useState } from "react";
import TimelineIcon from "@mui/icons-material/Timeline";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import PaidIcon from "@mui/icons-material/Paid";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import emailjs from "@emailjs/browser";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Link } from "react-scroll";
import Button from "@mui/material/Button";
import { useEffect } from "react";

const Home = () => {
  const [intakeData, setIntakeData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const submitBtn = useRef();

  const [blur, setBlur] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (blur) {
      validateValues(intakeData);
    }
  }, [intakeData, blur]);

  const handleBlur = () => {
    setBlur(true);
  };

  const validateValues = (inputValues) => {
    let newErrors = {};
    if (!inputValues.name) {
      newErrors.name = "Please enter a name.";
    } else if (inputValues.name.length < 2) {
      newErrors.name = "Name must be at least 2 letters.";
    }
    if (!inputValues.email) {
      newErrors.email = "E-mail address is required.";
    } else if (
      !inputValues.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      newErrors.email = "Please enter a proper e-mail address.";
    }
    if (!inputValues.message) {
      newErrors.message = "Please enter a message.";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleClose = () => {
    setOpenSuccess(false);
    setOpenFail(false);
  };

  const handleTextInput = (e) => {
    const nextFormState = {
      ...intakeData,
      [e.target.name]: e.target.value,
    };
    setIntakeData(nextFormState);
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const clearFields = () => {
    intakeData.name = "";
    intakeData.email = "";
    intakeData.message = "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateValues(intakeData);
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      emailjs
        .send(
          "service_i4gwvib",
          "template_f9zawki",
          templateParams,
          "ZgVDwqI0MSeqUWRP7"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
            clearFields();
            setOpenSuccess(true);
          },
          function (error) {
            console.log("FAILED...", error);
            clearFields();

            setOpenFail(true);
          }
        );
    }
  };

  const templateParams = {
    from_name: intakeData.name,
    reply_to: intakeData.email,
    message: intakeData.message,
  };

  return (
    <section className="home">
      <div className="featured">
        <div className="headline">
          Developing software with{" "}
          <div className="action">passion + precision</div>
        </div>
        <ul>
          <li style={{ color: "#008fb3", fontWeight: "bold" }}>
            <PaidIcon /> PROMOTION: full websites starting at $250 through the
            end of 2024
          </li>
          <li>
            <ImportantDevicesIcon /> Small business web design & development
          </li>
          <li>
            <PhoneAndroidIcon /> App development for mobile devices / watches
          </li>
          <li>
            <FlashOnIcon /> 100% hand-coded for optimized performance
          </li>
          <li>
            <TimelineIcon /> Quick client onboarding with affordable plans
          </li>

          <Link to="contact">
            <span
              className="btn"
              style={{ fontSize: "20px", marginTop: "10px" }}
            >
              Start Project
            </span>
          </Link>
        </ul>
      </div>
      <div className="form-wrapper">
        <Dialog onClose={handleClose} open={openSuccess}>
          <DialogTitle>
            Your message was sent successfully. You can expect a response within
            24 hours. Thanks for contacting us.
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>

        <Dialog onClose={handleClose} open={openFail}>
          <DialogTitle
            sx={{
              paddingTop: 5,
            }}
          >
            There was an error sending your message. Please feel free to send an
            e-mail directly to hello[at]syntaxlane.com.
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>

        <Box
          className="intake-form"
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            Got a project in mind?<br></br>Chat with our team.
          </Typography>
          <TextField
            id="name"
            name="name"
            label="Name"
            required
            type="text"
            variant="standard"
            aria-describedby="error"
            value={intakeData.name}
            onChange={handleTextInput}
            onBlur={handleBlur}
          />
          {errors.name ? (
            <Typography variant="body" className="error">
              {errors.name}
            </Typography>
          ) : null}
          <TextField
            id="email"
            name="email"
            label="Email"
            required
            type="email"
            variant="standard"
            aria-describedby="error"
            value={intakeData.email}
            onChange={handleTextInput}
            onBlur={handleBlur}
          />
          {errors.email ? (
            <Typography variant="body" className="error">
              {errors.email}
            </Typography>
          ) : null}

          <TextField
            id="message"
            name="message"
            label="Message"
            required
            multiline
            type="text"
            variant="standard"
            maxRows={4}
            value={intakeData.message}
            onChange={handleTextInput}
            onBlur={handleBlur}
          />
          {errors.message ? (
            <Typography variant="body" className="error">
              {errors.message}
            </Typography>
          ) : null}
          <Button
            style={{ width: "fit-content" }}
            type="submit"
            className="btn"
            variant="contained"
            onClick={handleSubmit}
            disabled={disabled}
            ref={submitBtn}
          >
            Submit
          </Button>
        </Box>
      </div>
    </section>
  );
};

export default Home;
