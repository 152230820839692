import "./App.css";
import Header from "./components/Header";
import About from "./components/About";
import Contact from "./components/Contact";
import Portfolio from "./components/Portfolio";
import Pricing from "./components/Pricing";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Home from "./components/Home";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useEffect, useState } from "react";

function App() {
  const [isArrowVisible, setIsArrowVisible] = useState(false);

  useEffect(() => {
    const handler = (entries) => {
      if (entries[0].isIntersecting) {
        setIsArrowVisible(false);
      } else {
        setIsArrowVisible(true);
      }
    };
    const observer = new window.IntersectionObserver(handler);
    observer.observe(document.querySelector(".header"));
  }, []);

  return (
    <div className="App">
      <Header />
      <Home />
      <Services />
      <Pricing />
      <Portfolio />
      <About />
      <Contact />
      <Footer />
      {isArrowVisible ? (
        <div className="scroll-up" onClick={() => window.scrollTo(0, 0)}>
          <ArrowUpwardIcon />
        </div>
      ) : null}
    </div>
  );
}

export default App;
