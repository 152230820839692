import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "../stylesheets/Contact.css";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useEffect } from "react";

const Contact = () => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const submitBtn = useRef();
  const [blur, setBlur] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (blur) {
      validateValues(contactData);
    }
  }, [contactData, blur]);

  const handleBlur = () => {
    setBlur(true);
  };

  const validateValues = (inputValues) => {
    let newErrors = {};
    if (!inputValues.name) {
      newErrors.name = "Please enter a name.";
    } else if (inputValues.name.length < 2) {
      newErrors.name = "Name must be at least 2 letters.";
    }
    if (!inputValues.email) {
      newErrors.email = "E-mail address is required.";
    } else if (
      !inputValues.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      newErrors.email = "Please enter a proper e-mail address.";
    }
    if (inputValues.phone) {
      if (
        !inputValues.phone.match(
          /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
        )
      ) {
        newErrors.phone = "Please enter a proper phone number.";
      }
    }
    if (!inputValues.message) {
      newErrors.message = "Please enter a message.";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleClose = () => {
    setOpenSuccess(false);
    setOpenFail(false);
  };

  const handleTextInput = (e) => {
    const nextFormState = {
      ...contactData,
      [e.target.name]: e.target.value,
    };
    setContactData(nextFormState);
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const clearFields = () => {
    contactData.name = "";
    contactData.email = "";
    contactData.phone = "";
    contactData.message = "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateValues(contactData);
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      emailjs
        .send(
          "service_i4gwvib",
          "template_f9zawki",
          templateParams,
          "ZgVDwqI0MSeqUWRP7"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
            clearFields();
            setOpenSuccess(true);
          },
          function (error) {
            console.log("FAILED...", error);
            clearFields();
            setOpenFail(true);
          }
        );
    }
  };

  const templateParams = {
    from_name: contactData.name,
    reply_to: contactData.email,
    message: contactData.message,
  };

  return (
    <section className="contact" id="contact">
      <div className="headline">Get In Touch</div>
      <Typography variant="h3">
        Please fill out the form below and our team will respond within 24
        hours.{" "}
      </Typography>

      <Dialog onClose={handleClose} open={openSuccess}>
        <DialogTitle>
          Your message was sent successfully. You can expect a response within
          24 hours. Thanks for contacting us.
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog onClose={handleClose} open={openFail}>
        <DialogTitle
          sx={{
            paddingTop: 5,
          }}
        >
          There was an error sending your message. Please feel free to send an
          e-mail directly to hello[at]syntaxlane.com.
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Box
        component="form"
        className="contact-form"
        noValidate
        autoComplete="off"
        sx={{
          maxWidth: "600px",
        }}
        onSubmit={handleSubmit}
      >
        <TextField
          fullWidth
          required
          label="Name"
          variant="standard"
          name="name"
          type="text"
          aria-describedby="error"
          value={contactData.name}
          onChange={handleTextInput}
          onBlur={handleBlur}
        />
        {errors.name ? (
          <Typography variant="body" className="error">
            {errors.name}
          </Typography>
        ) : null}
        <TextField
          fullWidth
          required
          label="E-mail"
          variant="standard"
          name="email"
          type="email"
          aria-describedby="error"
          value={contactData.email}
          onChange={handleTextInput}
          onBlur={handleBlur}
        />
        {errors.email ? (
          <Typography variant="body" className="error">
            {errors.email}
          </Typography>
        ) : null}
        <TextField
          label="Phone Number"
          fullWidth
          type="phone"
          variant="standard"
          name="phone"
          aria-describedby="error"
          value={contactData.phone}
          onChange={handleTextInput}
          onBlur={handleBlur}
        />
        {errors.phone ? (
          <Typography variant="body" className="error">
            {errors.phone}
          </Typography>
        ) : null}
        <TextField
          required
          label="Message"
          fullWidth
          multiline
          minRows={5}
          variant="standard"
          name="message"
          aria-describedby="error"
          value={contactData.message}
          onChange={handleTextInput}
          onBlur={handleBlur}
        />
        {errors.message ? (
          <Typography variant="body" className="error">
            {errors.message}
          </Typography>
        ) : null}
        <Button
          variant="outlined"
          type="submit"
          onClick={handleSubmit}
          disabled={disabled}
          ref={submitBtn}
        >
          Submit
        </Button>
      </Box>
    </section>
  );
};

export default Contact;
