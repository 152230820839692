import React, { useState } from "react";
import { Link } from "react-scroll";
import MenuIcon from "@mui/icons-material/Menu";
import LogoImg from "../assets/logo.png";

const Header = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const links = [
    {
      destination: "services",
      description: "Services",
    },
    {
      destination: "pricing",
      description: "Pricing",
    },
    // {
    //   destination: "portfolio",
    //   description: "Portfolio",
    // },
    {
      destination: "about",
      description: "About",
    },
    {
      destination: "contact",
      description: "Let's Talk",
    },
  ];

  return (
    <div>
      <div className="header">
        <div className="logo">
          <Link to="/">
            <img src={LogoImg} alt="Syntax Lane Logo" id="logo" />
          </Link>
        </div>

        <div className="center-links">
          {links.map((link, idx) => {
            return (
              <Link to={link.destination} key={idx}>
                <span className="btn">{link.description}</span>
              </Link>
            );
          })}
        </div>

        <div
          className="hamburger"
          onClick={() => setShowMobileNav((prev) => !prev)}
        >
          <MenuIcon />
          <div className="mobile-nav">
            {showMobileNav ? (
              <div className="mobile-nav-links">
                {links.map((link, idx) => {
                  return (
                    <Link to={link.destination} key={idx}>
                      <span>{link.description}</span>
                    </Link>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
